.multiselect-wrapper {
  line-height: 1;
  background-color: rgba(9, 30, 66, 0.04);
  border-radius: 3px;
  padding: 9px 12px;
  color: #42526e;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between; }

.multiselect-text-wrapper {
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.multiselect-placeholder {
  color: #7a869a; }

.multiselect-label {
  padding-right: 8px; }

.filter-overlay-wrapper {
  background-color: white; }
  .filter-overlay-wrapper .search-input {
    margin: 8px 8px 0;
    width: calc(100% - 2 * 8px); }
    .filter-overlay-wrapper .search-input .ant-input {
      margin-bottom: 0; }
    .filter-overlay-wrapper .search-input .ant-input-suffix {
      color: #0c71ff; }
  .filter-overlay-wrapper .select-all-wrapper {
    text-align: right;
    margin: 8px 8px 4px;
    font-size: 12px; }
    .filter-overlay-wrapper .select-all-wrapper span {
      text-decoration: underline;
      cursor: pointer;
      color: #7a869a; }

.ant-menu.filters-menu {
  overflow: auto;
  box-shadow: none; }
  .ant-menu.filters-menu .ant-checkbox-wrapper {
    margin-right: 10px; }
  .ant-menu.filters-menu .ant-menu-item {
    line-height: 1.6;
    height: auto;
    margin: 0;
    padding: 6px 10px;
    overflow: hidden;
    text-overflow: ellipsis; }
    .ant-menu.filters-menu .ant-menu-item:not(:last-child) {
      margin-bottom: 0; }

.ant-dropdown > *,
.ant-dropdown > .ant-menu.ant-menu-root {
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  overflow: auto;
  box-shadow: -1px 7px 13px rgba(0, 0, 0, 0.25); }
