.filter-overlay-wrapper {
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal; }

.filters-panel {
  display: flex;
  align-items: center;
  color: #42526e; }
  .filters-panel .filters-search {
    width: 220px;
    background-color: #fafbfc;
    color: inherit; }
    .filters-panel .filters-search .ant-input {
      margin-bottom: 0;
      background-color: inherit;
      color: inherit; }
      .filters-panel .filters-search .ant-input::placeholder {
        color: #7a869a; }
  .filters-panel .filter-item {
    margin-left: 15px;
    width: 150px;
    display: inline-block; }
    .filters-panel .filter-item:first-of-type {
      margin-left: 0; }
  .filters-panel .filter-checkbox-group {
    color: #5b6771;
    display: inline-block;
    margin-left: 30px; }
    .filters-panel .filter-checkbox-group label {
      font-weight: normal; }
    .filters-panel .filter-checkbox-group .ant-checkbox-wrapper {
      color: inherit; }
      .filters-panel .filter-checkbox-group .ant-checkbox-wrapper span:nth-child(2) {
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis; }
    .filters-panel .filter-checkbox-group .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 15px; }
  .filters-panel .filter-divider {
    margin-left: 15px;
    border-left: 1px solid #c4c4c4;
    padding: 1em 0; }
